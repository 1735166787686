import { Components, Paths } from "@/openapi";
import { isValid } from "iban";
import { z } from "zod";
import {
  countries,
  currencies,
  defaultCountry,
  defaultCurrency,
  defaultDocumentLocale,
  defaultTimezone,
  documentLocales,
  timezones,
} from "@evenli-platform/core/utils/constants";

export type Memberships =
  Paths.GetUserMemberships.Responses.$200["memberships"];

// Schema for SIGN IN form
export const signInSchema = z.object({
  email: z.string().email({ message: "enter_a_valid_email_address" }),
  password: z.string().min(8, "password_must_be_at_least_8_characters"),
});
export type SignInSchemaType = z.infer<typeof signInSchema>;

export const codeRequestFormSchema = z.object({
  email: z.string().email({ message: "enter_a_valid_email_address" }),
});
export type CodeRequestSchemaType = z.infer<typeof codeRequestFormSchema>;

export const newPasswordSchema = z
  .object({
    code: z.string().min(1, "this_field_is_required"),
    password: z
      .string()
      .min(8, "password_must_be_at_least_8_characters")
      .refine((value) => /[a-z]/.test(value), {
        message: "password_must_contain_lowercase",
      })
      .refine((value) => /[A-Z]/.test(value), {
        message: "password_must_contain_uppercase",
      })
      .refine((value) => /[0-9]/.test(value), {
        message: "password_must_contain_number",
      })
      .refine((value) => /[\W_]/.test(value), {
        message: "password_must_contain_special_character",
      }),
    confirmPassword: z.string(),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "passwords_must_match",
    path: ["confirmPassword"],
  });
export type NewPasswordSchemaType = z.infer<typeof newPasswordSchema>;

// Schema for SIGN UP form
export const signUpSchema = z
  .object({
    email: z.string().email(),
    firstName: z.string().min(1, "this_field_is_required"),
    lastName: z.string().min(1, "this_field_is_required"),
    phoneNumber: z
      .string()
      .regex(/^\+\d*$/, "phone_number_must_be_valid")
      .min(10, "phone_number_is_too_short"),
    password: z
      .string()
      .min(8, "password_must_be_at_least_8_characters")
      .refine((value) => /[a-z]/.test(value), {
        message: "password_must_contain_lowercase",
      })
      .refine((value) => /[A-Z]/.test(value), {
        message: "password_must_contain_uppercase",
      })
      .refine((value) => /[0-9]/.test(value), {
        message: "password_must_contain_number",
      })
      .refine((value) => /[\W_]/.test(value), {
        message: "password_must_contain_special_character",
      }),
    confirmPassword: z.string(),
    terms: z.boolean(),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "passwords_must_match",
    path: ["confirmPassword"],
  });

export type SignUpSchemaType = z.infer<typeof signUpSchema>;

// Schema for ORGANISATION DETAILS form
export const orgDetailsSchema = z.object({
  name: z.string().min(1, "company_name_is_required"),
  businessId: z.string().min(1),
  country: z.enum(countries).default(defaultCountry),
  city: z.string().min(1, "city_is_required"),
  addressLine: z.string().min(1, "this_field_is_required"),
  postalCode: z.string().min(5, "min_5_digits"),
  timezone: z.enum(timezones).default(defaultTimezone),
  documentLocale: z.enum(documentLocales).default(defaultDocumentLocale),
});

export type OrgDetailsSchemaType = z.infer<typeof orgDetailsSchema>;

// Schema for BILLING INFORMATION form
export const billingInfoSchema = z
  .object({
    isAnotherCompany: z.boolean(),
    billingBusinessName: z.string().nullable().optional(),
    billingBusinessId: z.string().nullable().optional(),
    billingEmail: z.string().email(),
    billingEinvoicingAddress: z.string().min(1),
    billingEinvoicingOperator: z.string().min(1),
    billingAdditionalInformation: z.string().optional(),
    billingIsVatExempt: z.boolean(),
    billingAddressLine: z.string().optional(),
    billingCity: z.string().optional(),
    billingPostalCode: z.string().optional(),
    billingCountry: z.enum(countries).optional(),
    billingBankAccountNumber: z
      .string()
      .refine((iban) => isValid(iban), { message: "invalid_iban" })
      .optional(),
    billingBic: z
      .string()
      .max(11, "bic_must_be_at_most_11_characters")
      .optional(),
    billingCurrency: z.enum(currencies).default(defaultCurrency),
    isAnotherPostalAddress: z.boolean(),
    netPaymentTerm: z.number().optional(),
    billingContactPersonName: z.string().optional(),
    billingBuyerReference: z.string().optional(),
    billingOrderIdentifier: z.string().optional(),
  })
  // .refine(
  //   (data) => {
  //     return !data.isAnotherCompany || !!data.billingBusinessName;
  //   },
  //   {
  //     message: "this_field_is_required",
  //     path: ["billingBusinessName"],
  //   },
  // )
  .refine(
    (data) => {
      return !data.isAnotherCompany || !!data.billingBusinessId;
    },
    {
      message: "this_field_is_required",
      path: ["billingBusinessId"],
    },
  )
  .refine(
    (data) => {
      return !data.isAnotherPostalAddress || !!data.billingAddressLine;
    },
    {
      message: "this_field_is_required",
      path: ["billingAddressLine"],
    },
  )
  .refine(
    (data) => {
      return !data.isAnotherPostalAddress || !!data.billingCity;
    },
    {
      message: "this_field_is_required",
      path: ["billingCity"],
    },
  )
  .refine(
    (data) => {
      return !data.isAnotherPostalAddress || !!data.billingCountry;
    },
    {
      message: "this_field_is_required",
      path: ["billingCountry"],
    },
  )
  .refine(
    (data) => {
      return !data.isAnotherPostalAddress || !!data.billingPostalCode;
    },
    {
      message: "this_field_is_required",
      path: ["billingPostalCode"],
    },
  );

export type BillingInfoSchemaType = z.infer<typeof billingInfoSchema>;

// Schema for ELECTRICITY PRICING SETTINGS form
export const TARIFF_TYPES = ["spot-standard", "energy-fixed"] as const;

export const electPricingSchema = z.object({
  universalPrice: z.string(),
  tariffType: z.enum(TARIFF_TYPES),
});
export type TElectPricingSchema = z.infer<typeof electPricingSchema>;

// Schema for SITE CHANGING form
export const siteChangeSchema = z.object({
  name: z.string(),
  streetAddress: z.string(),
  city: z.string(),
  postalCode: z.string(),
  country: z.enum(countries),
  latitude: z.number().min(-90).max(90),
  longitude: z.number().min(-180).max(180),
  visibleOnMap: z.boolean(),
  public: z.boolean(),
});

export enum Status {
  NotInitialized = "",
  InvitationNotFound = "Invitation not found",
  SomethingWentWrong = "Something went wrong",
  Authorized = "Authorized user",
  Unauthorized = "Unauthorized user",
}

export type OrganizationSites =
  Paths.GetOrganizationSites.Responses.$200["sites"];

export type OrganizationStatistics = Paths.GetStatistics.Responses.$200;
export type OrganizationStatisticsOverview =
  Paths.GetStatisticsOverview.Responses.$200;

export type OrganizationSite = Components.Schemas.OneChargerGroupReply;

export type Charger = Components.Schemas.ChargerReply;
